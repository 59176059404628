import React from 'react'
import './DefaultPackageItem.css'

export default function DefaultPackageItem({ item, shortcodeDetails, features, checkoutUrl, active = false }) {
	return (
		<div className={["pricing-items", item.recommended || active ? 'active' : null].join(' ')}>
			<div className="in-pricing-items">
				{item.recommended && <div className="most-popular">Most Popular</div>}
				{active && <div className="most-popular">Hot Deal</div>}
				<div className="head">
					{/* <span className="save">Save 69%</span> */}
					<h3 className="price-title">{item.name} {item.featured_text &&
                        <span className='featured-text'> {item.featured_text}</span>
                    }</h3>
					<strong className="price">
						{
							item.formatted_price
						//(item.price_type === 'one_time' && item.package?.default_price_id == null) ? item.formatted_price : item.formatted_price_with_interval
						}
						</strong>
					<p dangerouslySetInnerHTML={{ __html: item.package_description }}></p>
					<a className="select-plan-btn" href={process.env.NEXT_PUBLIC_CHECKOUT_URL + "/?price_id=" + item.price_id}>Select Plan</a>
					<div className="include">OysterVPN Includes:</div>
				</div>
				<div className="content">
					<ul className="plan-list">
						{features && features.map((feature, index) => (
							<li key={index}><span className="icon green-text"><i className="fa-solid fa-check"></i></span> {feature}</li>
						))}
					</ul>
				</div>

			</div>
		</div>
	)
}