"use client"

import { useThemeContext } from "@/app/theme-provider";
import Link from "next/link";

function BuyButton(props) {

    const { shortcodeDetails } = useThemeContext();
    const url = props.url ?? "/buy-vpn/";

    return (
        <div className={["d-inline-block", props.className].join(" ")}>
            <Link href={url} className={["mybtn mybtn-default", props.buttonClass ?? "mybtn-small"].join(" ")}>{props.buttonText ?? "Get OysterVPN"}
                <i className={[props.icon ?? "fas fa-chevron-circle-right"].join(" ")}></i>
            </Link>
            {props.moneyBack &&
                <p className={["text-small font-weight-500 mt-2", props.moneyBackClass].join(" ")}>{shortcodeDetails?.money_back} Money Back Guarantee</p>
            }
        </div>
    )
}



export default BuyButton;